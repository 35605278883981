import React from 'react';

import cls from 'classnames';

import './ContentContainer.scss';

export interface ContentContainerProps {
  hide: boolean;
  children: JSX.Element | JSX.Element[];
}

export const ContentContainer = ({ hide, children }: ContentContainerProps) => (
  <div className={cls('dropdown-content-container', { hide })}>
    <div className="header"></div>
    <div className="content">{children}</div>
  </div>
);
