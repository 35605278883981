import React, { ChangeEvent, FocusEvent, InputHTMLAttributes, useEffect, useState } from 'react';

import cls from 'classnames';

import { ClassName } from '../../types';
import { Icon } from '../Icon';

import './TextField.scss';

export interface TextFieldProps extends ClassName, InputHTMLAttributes<HTMLInputElement> {
  icon?: string;
  value?: string;
  label?: string;
  hint?: string;
  error?: string;
  disabled?: boolean;
  password?: boolean;
  large?: boolean;
  small?: boolean;
  children?: JSX.Element | JSX.Element[];
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const TextField = ({
  icon,
  value,
  label,
  hint,
  error,
  disabled,
  password,
  large,
  small,
  children,
  onChange,
  className,
  ...attrs
}: TextFieldProps) => {
  const [moveLabel, setMoveLabel] = useState(!!value || !!attrs.placeholder || false);
  const type = password ? 'password' : 'text';

  const handleOnFocus = (e: FocusEvent<HTMLInputElement>) => {
    setMoveLabel(true);
    attrs.onFocus?.(e);
  };

  const handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (!value && !attrs.placeholder) {
      setMoveLabel(false);
    }
    attrs.onFocus?.(e);
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e);
  };

  useEffect(() => {
    setMoveLabel(!!value);
  }, [value]);

  return (
    <div className={cls('text-field', className, { small, large, disabled, error })}>
      {icon && (
        <div className="icon-container">
          <Icon name={icon} size={20} color="ui-secondary" />
        </div>
      )}

      <div className="input-container">
        <input
          {...attrs}
          className="hl-big"
          value={value}
          disabled={disabled}
          type={type}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
        />
        {label && (
          <div className={cls('label', { 'label-top': moveLabel })}>
            <div className="label-text">{label}</div>
          </div>
        )}
        {hint && !error && <div className="hint">{hint}</div>}
        {error && <div className="error">{error}</div>}
      </div>
      <div className="children-container">{children}</div>
    </div>
  );
};
