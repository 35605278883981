import React from 'react';

import cls from 'classnames';

import { ClassName } from '../../types';
import { Icon } from '../Icon';
import { ContentContainer } from './ContentContainer';
import { clamp } from './utils';
import { getBrandIcon } from './utils';

import './Dropdown.scss';

export interface DropdownProps extends ClassName {
  brand?: string;
  icon?: string;
  text?: string;
  label?: string;
  alarm?: boolean;
  expand?: boolean;
  fullText?: boolean;
  children?: JSX.Element[];
  onClick?: () => void;
}

export const Dropdown = (props: DropdownProps) => {
  const { brand, icon, text, label, alarm, expand, fullText, children, onClick } = props;

  if (!brand && !icon && !text) {
    throw new Error('Dropdown must have one of the following props: brand, icon, text.');
  }

  return (
    <div className="dropdown">
      <div className={cls('outer-container', { alarm })}>
        {brand && (
          <div className="brand">
            <Icon name={getBrandIcon(brand)} size={14} />
            <span className="bold">{brand}</span>
          </div>
        )}
        {label && <span className="label">{fullText ? label : clamp(label, 12)}</span>}
        <div className={cls('inner-container', { alarm })} onClick={onClick}>
          {icon && <Icon name={icon} size={24} color="ui-solid" />}
          {text && <span className="text">{text}</span>}
        </div>
      </div>

      {children && <ContentContainer hide={!expand}>{children}</ContentContainer>}
    </div>
  );
};
