import { Colors } from './types/colors';

export const darkBlue: Colors = {
  // Brand
  brandPrimary: '#5466fd',
  brandHover: '#2d48d6',
  brandSecondary: '#bdccff',
  brandThirtiary: '#eef6ff',
  brandElement: '#6587ff',
  brandBackground: '#e5ebff',
  brandActive: '#6587ff',
  brandDisabled: '#9094ff',
  brandPrimaryText: '#ffffff',
  brandSecondaryText: '#ffffff',
  brandActiveText: '#ffffff',
  brandDisabledText: 'rgba(255, 255, 255, 0.8)',
  brandHoverText: 'rgba(255, 255, 255, 0.8)',

  // UI
  uiMain: '#fbfbfb',
  uiSecondary: '#9fa3c1',
  uiBorder: '#9093b2',
  uiElementBackground: '#4a4a73',
  uiInactive: '#5b5b86',
  uiMainBackground: '#404068',
  uiWebBackground: '#323252',
  uiSeparator: '#5e5e87',
  uiNeutral: '#7177a2',
  uiContrast: '#5a6085',
  uiKey: '#7177a4',
  uiMainSelected: '#515469',
  uiElementSecondary: '#565686',
  uiOutline: '#646985',
  uiSolid: '#ffffff',
  uiHover: 'rgba(145, 150, 179, 0.15)',
  uiMenu: '#343458',

  // Attention
  attentionAlarm: '#ff3877',
  attentionAlarmHover: '#fa5a8d',
  attentionAlarmBorder: '#e584a3',
  attentionAlarmBackground: '#6D3952',
  attentionAlarmInactive: '#92415b',

  attentionWarning: '#fad30b',
  attentionWarningBackground: '#fdf8dc',

  attentionNeutral: '#9f566c',
  attentionPositive: '#95f972',
  attentionAlarmTransaction: '#ff8ab4',

  attentionAlarmSecondary: '#ed1eff',
  attentionAlarmHoverSecondary: '#c233ce',
  attentionAlarmBorderSecondary: '#d284d9',
  attentionAlarmBackgroundSecondary: '#f8dafb',
  attentionAlarmInactiveSecondary: '#f1d4f3',

  // Effects
  topBarShadow: '0px 8px 22px rgba(26, 29, 53, 0.4)',
  mainWindowShadow: '0px 8px 24px rgba(37, 39, 56, 0.8)',
  selectedWindowShadow: '0px 18px 41px rgba(27, 29, 48, 0.79)',

  popover: 'rgba(73, 75, 87, 0.95)',
  popoverShadow: '0px 4px 21px rgba(30, 33, 49, 0.81)',

  popoverDark: 'rgba(48, 50, 64, 0.95)',
  popoverDarkShadow: '0px 7px 24px rgba(13, 17, 26, 0.34)',

  inputField: 'rgba(84, 86, 101, 0.15)',
  inputFieldBright: 'rgba(217, 230, 240, 0.05)',

  blueShadow: '0px 7px 28px rgba(84, 102, 253, 0.57)',

  overlay: 'rgba(240, 241, 254, 0.65)',
  overlayModal: '#4d4c57',

  // Tiles
  darkTileShadow: '0px 7px 19px rgba(25, 27, 36, 0.52)',
  blueTileShadow: '0px 7px 19px rgba(25, 34, 105, 0.52)',
  whiteTileShadow: '0px 8px 24px rgba(37, 39, 55, 0.8)',
  darkTileHoverShadow: '0px 7px 22px rgba(25, 26, 39, 0.62)',
  blueTileHoverShadow: '0px 7px 22px rgba(25, 34, 105, 0.62)',
  whiteTileHoverShadow: '0px 8px 24px rgba(37, 39, 55, 0.8)',
};
