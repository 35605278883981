import { Colors } from './types/colors';

export const darkRed: Colors = {
  // Brand
  brandPrimary: '#5466fd',
  brandHover: '#2d48d6',
  brandSecondary: '#bdccff',
  brandThirtiary: '#eef6ff',
  brandElement: '#6587ff',
  brandBackground: '#e5ebff',
  brandActive: '#6587ff',
  brandDisabled: '#9094ff',
  brandPrimaryText: '#ffffff',
  brandSecondaryText: '#ffffff',
  brandActiveText: '#ffffff',
  brandDisabledText: 'rgba(255, 255, 255, 0.8)',
  brandHoverText: 'rgba(255, 255, 255, 0.8)',

  // UI
  uiMain: '#fbfbfb',
  uiSecondary: '#a89f9f',
  uiBorder: '#9d9090',
  uiElementBackground: '#594f4f',
  uiInactive: '#675b5b',
  uiMainBackground: '#4c4343',
  uiWebBackground: '#3c3434',
  uiSeparator: '#8a7b7b',
  uiNeutral: '#8a7b7b',
  uiContrast: '#706666',
  uiKey: '#8a7b7b',
  uiMainSelected: '#706666',
  uiElementSecondary: '#6c6060',
  uiOutline: '#736868',
  uiSolid: '#ffffff',
  uiHover: 'rgba(168, 156, 156, 0.15)',
  uiMenu: '#3f383c',

  // Attention
  attentionAlarm: '#ff3877',
  attentionAlarmHover: '#fa5a8d',
  attentionAlarmBorder: '#e584a3',
  attentionAlarmBackground: '#684053',
  attentionAlarmInactive: '#91435c',

  attentionWarning: '#fad30b',
  attentionWarningBackground: '#fdf8dc',

  attentionNeutral: '#af5570',
  attentionPositive: '#95f972',
  attentionAlarmTransaction: '#ff8ab4',

  attentionAlarmSecondary: '#ed1eff',
  attentionAlarmHoverSecondary: '#c233ce',
  attentionAlarmBorderSecondary: '#d284d9',
  attentionAlarmBackgroundSecondary: '#f8dafb',
  attentionAlarmInactiveSecondary: '#f1d4f3',

  // Effects
  topBarShadow: '0px 8px 22px rgba(28, 21, 21, 0.4)',
  mainWindowShadow: '0px 8px 24px rgba(41, 34, 34, 0.8)',
  selectedWindowShadow: '0px 18px 41px rgba(40, 33, 33, 0.79)',

  popover: '#4c4343',
  popoverShadow: '0px 4px 21px rgba(40, 33, 33, 0.81)',

  popoverDark: '#4c4343',
  popoverDarkShadow: '0px 7px 24px rgba(13, 18, 28, 0.34)',

  inputField: '#4c4343',
  inputFieldBright: '##4C4343',

  blueShadow: '0px 7px 28px rgba(31, 51, 216, 0.57)',

  overlay: '#4c4343',
  overlayModal: '#4d4c57',

  // Tiles
  darkTileShadow: '0px 7px 19px rgba(21, 17, 17, 0.52)',
  blueTileShadow: '0px 7px 19px rgba(22, 41, 199, 0.52)',
  whiteTileShadow: '0px 8px 24px rgba(45, 42, 42, 0.8)',
  darkTileHoverShadow: '0px 7px 22px rgba(21, 17, 17, 0.62)',
  blueTileHoverShadow: '0px 7px 22px rgba(28, 44, 194, 0.62)',
  whiteTileHoverShadow: '0px 8px 24px rgba(45, 42, 42, 0.8)',
};
