import React from 'react';

import { Icon } from '../Icon';

import './Stepper.scss';

type ChildrenProps = JSX.Element | JSX.Element[] | string;

export interface StepperProps {
  children: JSX.Element | JSX.Element[];
}

export const Stepper = ({ children }: StepperProps) => {
  return (
    <>
      <div className="stepper">{children}</div>
    </>
  );
};

interface StepProps {
  children: ChildrenProps;
  stepNumber: number;
  activeStep: number;
  title: string;
  subtitle: string;
}

export const Step = ({ children, stepNumber, activeStep, title, subtitle }: StepProps) => {
  return (
    <>
      <div
        className={`step ${stepNumber === activeStep && 'step-active'} ${
          (stepNumber > activeStep || stepNumber < activeStep - 1) && 'step-away'
        }`}
      >
        <div className="step-head">
          <div className="step-number">{stepNumber + 1}</div>
          <div className="step-header">
            <div className="step-title">{title}</div>
            <div className="step-subtitle">{subtitle}</div>
          </div>
          {stepNumber < activeStep && (
            <Icon
              name="nav-circle-ok"
              size={20}
              color="brand-primary"
              className="step-check-icon d-flex ml-auto"
            />
          )}
        </div>

        <div className="step-body">
          <div className="step-line"></div>
          <div className="step-content">
            <div className="step-subtitle">{subtitle}</div>
            {children}
            <div className="step-divider"></div>
          </div>
        </div>
      </div>
    </>
  );
};

interface StepPanelProps {
  children: ChildrenProps;
}

export const StepPanel = ({ children }: StepPanelProps) => {
  return <div className="step-panel">{children}</div>;
};

interface StepButtonsProps {
  children: ChildrenProps;
}

export const StepButtons = ({ children }: StepButtonsProps) => {
  return <div className="step-buttons">{children}</div>;
};
