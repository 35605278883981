import React, { useContext } from 'react';

import { Icon } from '../Icon';
import { ThemeContext } from '../Theme';

import './Notification.scss';

export type NotificationType =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark';

export interface NotificationProps {
  className?: string;
  type: NotificationType;
  closable?: boolean;
  text: string | JSX.Element;
  customIcon?: string;
  onClose?: () => void;
}

const icons: Record<string, string> = {
  primary: 'nav-circle-ok',
  secondary: 'nav-attention-1',
  success: 'nav-circle-ok',
  danger: 'nav-circle-cancel',
  warning: 'nav-attention-1',
  info: 'nav-attention-1',
  light: 'nav-attention-1',
  dark: 'nav-attention-1',
};

export function Notification({
  className,
  type,
  closable = true,
  text,
  customIcon,
  onClose,
}: NotificationProps) {
  useContext(ThemeContext);

  const icon = customIcon || icons[type];

  return (
    <div className={`notification notification-${type} ${className ? className : ''}`}>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <Icon name={icon} size={24} className="mr-4" color="ui-main" />
          {text}
        </div>
        {closable && (
          <div className="d-flex align-items-center close-button" onClick={onClose}>
            <Icon name="nav-cross" size={14} className="ml-4" color="ui-main" />
          </div>
        )}
      </div>
    </div>
  );
}
