import chroma from 'chroma-js';

import { Colors, themes } from '../../../themes';
import { camelToKebab } from './camel-to-kebab';

export const applyTheme = (theme: string, brandColor?: string) => {
  if (!Object.keys(themes).includes(theme)) {
    throw new Error(`Theme '${theme}' does not exist`);
  }

  const themeVars = createCssVariables(themes[theme]);
  applyCssVariables(themeVars);

  if (brandColor) {
    // Check for a color value to catch some easy mistakes
    if (/^(#|rgb)*/.test(brandColor)) {
      const brandVars = createBrandVariables(brandColor);
      applyCssVariables(brandVars);
    } else {
      console.warn(
        'Warning: not overriding primary theme color. Please supply in hex of rgb(a) format.',
      );
    }
  }
};

export const createCssVariables = (theme: Colors) =>
  Object.keys(theme).reduce((acc: Record<string, string>, camelCaseKey: string) => {
    const kebabKey = camelToKebab(camelCaseKey);
    const color = theme[camelCaseKey];

    acc[`--${camelCaseKey}`] = color;
    acc[`--${kebabKey}`] = color;

    return acc;
  }, {});

export const createBrandVariables = (brandColor: string) => {
  const hoverColor = chroma(brandColor).darken(0.6).hex();
  const activeColor = chroma(brandColor).darken(0.8).hex();
  const disabledColor = chroma(brandColor).desaturate(2).hex();
  const brandPrimaryText = chroma(brandColor).get('lab.l') > 95 ? '#000000' : '#ffffff';

  return {
    '--brand-primary': brandColor,
    '--brand-primary-text': brandPrimaryText,
    '--brand-secondary': hoverColor,
    '--brand-secondary-text': chroma(hoverColor).get('lab.l') > 95 ? '#000000' : '#ffffff',
    '--brand-hover': hoverColor,
    '--brand-hover-text': chroma(hoverColor).get('lab.l') > 95 ? '#000000' : '#ffffff',
    '--brand-active': activeColor,
    '--brand-active-text': chroma(activeColor).get('lab.l') > 95 ? '#000000' : '#ffffff',
    '--brand-disabled': disabledColor,
    '--brand-disabled-text': chroma(brandPrimaryText).alpha(0.8).hex(),
  };
};

// Adds a css property to the html element for each theme variable in both camel- and kebab-case
export const applyCssVariables = (cssVars: Record<string, string>) => {
  Object.keys(cssVars).forEach(key => {
    document.documentElement.style.setProperty(key, cssVars[key]);
  });
};
