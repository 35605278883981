import React, { createContext, useEffect, useState } from 'react';

import { Colors } from '../../themes';
import { themes } from '../../themes';
import { applyTheme, createThemeContext } from './utils';

export interface ThemeProps {
  theme: string;
  primaryColor?: string;
  children: JSX.Element;
}

export interface ThemeContextSchema {
  theme: string;
  colors: Colors;
  colorsCamel: Record<string, string>;
  colorsKebab: Record<string, string>;
  primaryColor?: string;
}

const defaultContext = createThemeContext('light');
export const ThemeContext = createContext(defaultContext);

export const ThemeProvider = ({ theme, primaryColor, children }: ThemeProps) => {
  if (!Object.keys(themes).includes(theme)) {
    throw new Error(
      `Theme "${theme}" not found. Please use any of these: ${Object.keys(themes).join(', ')}`,
    );
  }

  const [themeContext, setThemeContext] = useState<ThemeContextSchema>(defaultContext);

  useEffect(() => {
    setThemeContext(createThemeContext(theme));
    applyTheme(theme, primaryColor);
  }, [theme, primaryColor]);

  return <ThemeContext.Provider value={themeContext}>{children}</ThemeContext.Provider>;
};
