import React from 'react';

import cls from 'classnames';

import { ClassName } from '../../types';

import './Icon.scss';

export interface IconProps extends ClassName {
  color?: string; // 'brand-primary' or 'ui-neutral' etc
  name?: string;
  src?: string;
  size: 8 | 12 | 14 | 16 | 18 | 20 | 24 | 32;
  className?: string;
  onClick?: () => void;
}

export const Icon = (props: IconProps) => {
  const { color, name, src, size, className, onClick } = props;

  if (!(name || src) || !size) {
    throw new Error('Name/src and size needs to be available to produce an Icon');
  }

  const url = src || `/icons/${name}.svg`;

  return (
    <div className={cls('icon', className, { pointer: !!onClick })} onClick={onClick}>
      {color ? (
        <div
          className="mask"
          style={{
            WebkitMaskImage: `url('${url}')`,
            WebkitAlignItems: 'center',
            WebkitMaskRepeat: 'no-repeat',
            WebkitMaskSize: '100%',
            mask: `url('${url}') center center/100% 100% no-repeat`,
            backgroundColor: `var(--${color})`,
            width: `${size}px`,
            height: `${size}px`,
          }}
        />
      ) : (
        <img
          src={`${url}`}
          style={{
            width: `${size}px`,
            height: `${size}px`,
          }}
        />
      )}
    </div>
  );
};
