import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';

import { Button } from '../Button';
import { Icon } from '../Icon';
import { TextField, TextFieldProps } from '../TextField';

import './TokenInput.scss';

export interface TokenInputProps extends Omit<TextFieldProps, 'children' | 'onChange'> {
  balance: string;
  token: string;
  icon: string;
  onChange?: (value: string) => void;
  onError?: (value: any) => boolean;
}

export const TokenInput = (props: TokenInputProps) => {
  const [error, setError] = useState('');
  const { balance, disabled, icon, token, error: parentError, onChange, onError, ...attrs } = props;
  const pattern = RegExp(`^[0-9]*[.]?[0-9]*$`);
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value.replace(/[^0-9\.]/g, ''));
  };

  useEffect(() => {
    setError(
      props.value
        ? pattern.test(props.value)
          ? Number(props.value) > Number(balance)
            ? `Insufficient ${token} balance`
            : ''
          : 'Invalid'
        : '',
    );
    onError?.(!!error);
  }, [error, props.value, balance, pattern, token, onError]);

  return (
    <div className="token-input">
      <div className="top-area">
        <span className="hl-regular color-secondary">Available:</span>
        <Icon className="icon" src={icon} size={18} />
        <span className="h-regular color-secondary">{balance}</span>
      </div>

      <TextField
        {...attrs}
        className="text-field"
        type="text"
        disabled={disabled}
        error={parentError || error}
        onChange={handleOnChange}
        onKeyDown={(e: KeyboardEvent<any>) => {
          if ((e.target as HTMLInputElement).value.split('.').length > 1 && e.key == '.')
            e.preventDefault();
        }}
        onPaste={(e: any) => e.preventDefault()}
        pattern="^[0-9]*[.]?[0-9]*$"
      >
        <Icon src={icon} size={24} />
        <div className="token-name hl-big">{token}</div>
        <Button
          className="button"
          size="sm"
          type="primary"
          disabled={disabled}
          onClick={() => {
            onChange?.(balance);
          }}
        >
          Max
        </Button>
      </TextField>
    </div>
  );
};
