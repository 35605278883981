import React, { useContext } from 'react';

import { Icon } from '../Icon';
import { ThemeContext } from '../Theme';

import './Button.scss';

export interface ButtonProps {
  children?: any;
  style?: object;
  className?: string;
  type: 'primary' | 'secondary' | 'alarm';
  disabled?: boolean;
  loading?: boolean;
  loadingText?: string | JSX.Element;
  size?: 'lg' | 'sm' | 'md';
  onClick?: () => void;
}

export function Button({
  className,
  children,
  style,
  type,
  loading,
  loadingText,
  size = 'md',
  ...attrs
}: ButtonProps) {
  // const theme = useContext(ThemeContext);
  useContext(ThemeContext);
  return (
    <button
      {...attrs}
      className={`btn btn-${type} ${size ? 'btn-' + size : ''} ${loading ? 'is-loading' : ''} ${
        className ? className : ''
      }`}
      style={style}
    >
      {!loading ? (
        children
      ) : (
        <>
          <Icon
            color="brand-primary"
            name="spinner"
            size={size === 'sm' ? 14 : 24}
            className="mr-2"
          />
          {loadingText}
        </>
      )}
    </button>
  );
}
