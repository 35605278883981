import React, { ChangeEvent } from 'react';

import cls from 'classnames';

import './Slider.scss';

export interface SliderProps {
  color: 'primary' | 'secondary' | 'process';
  step: number;
  value: number;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

export const Slider = (props: SliderProps) => {
  const { color, step, value, disabled, onChange } = props;

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.parentElement?.style.setProperty('--value', e.target.value);
    e.target.parentElement?.style.setProperty('--text-value', JSON.stringify(e.target.value));

    onChange?.(e.target.value);
  };

  const style = {
    '--step': `${step}`,
    '--value': `${value}`,
    '--text-value': JSON.stringify(`${value}`),
  } as React.CSSProperties;

  return (
    <div
      className={cls('slider', `slider--${color}`, { 'slider--disabled': disabled })}
      style={style}
    >
      <input
        disabled={!!disabled}
        type="range"
        step={step}
        min="0"
        max="100"
        value={value}
        onChange={handleOnChange}
      />
      <output></output>
      <div className="slider__progress">
        <div className="ticks ticks--desktop">
          <span>0</span>
          <span>20%</span>
          <span>40%</span>
          <span>60%</span>
          <span>80%</span>
          <span>100%</span>
        </div>
        <div className="ticks ticks--mobile">
          <span>0</span>
          <span>25%</span>
          <span>50%</span>
          <span>75%</span>
          <span>100%</span>
        </div>
      </div>
    </div>
  );
};
