import { dark } from './dark';
import { darkBlue } from './dark-blue';
import { darkRed } from './dark-red';
import { light } from './light';
import { Colors } from './types';

export * from './types';

export const themes: Record<string, Colors> = {
  dark,
  light,
  dark_blue: darkBlue,
  dark_red: darkRed,
};
