import { Colors } from './types/colors';

export const light: Colors = {
  // Brand
  brandPrimary: '#5466fd',
  brandHover: '#2d48d6',
  brandSecondary: '#bdccff',
  brandThirtiary: '#eef6ff',
  brandElement: '#6587ff',
  brandBackground: '#e5ebff',
  brandActive: '#6587ff',
  brandDisabled: '#9094ff',
  brandPrimaryText: '#ffffff',
  brandSecondaryText: '#ffffff',
  brandActiveText: '#ffffff',
  brandDisabledText: 'rgba(255, 255, 255, 0.8)',
  brandHoverText: 'rgba(255, 255, 255, 0.8)',

  // UI
  uiMain: '#484a57',
  uiSecondary: '#9296ad',
  uiBorder: '#b3b7ce',
  uiElementBackground: '#fcfcff',
  uiInactive: '#f0f1fe',
  uiMainBackground: '#ffffff',
  uiWebBackground: '#f6f6fa',
  uiSeparator: '#f9f9fc',
  uiNeutral: '#d0d3e0',
  uiContrast: '#676978',
  uiKey: '#e8e9f0',
  uiMainSelected: '#555765',
  uiElementSecondary: '#f6f6fe',
  uiOutline: '#e0e1eb',
  uiSolid: '#ffffff',
  uiHover: 'rgba(148, 152, 176, 0.08)',
  uiMenu: '#474a5d',

  // Attention
  attentionAlarm: '#ed2f6a',
  attentionAlarmHover: '#cc3d6a',
  attentionAlarmBorder: '#e79bb3',
  attentionAlarmBackground: '#ffeef4',
  attentionAlarmInactive: '#f9dbe4',

  attentionWarning: '#fad30b',
  attentionWarningBackground: '#fdf8dc',

  attentionNeutral: '#e4b6c4',
  attentionPositive: '#69d443',
  attentionAlarmTransaction: '#fa5c8d',

  attentionAlarmSecondary: '#ed1eff',
  attentionAlarmHoverSecondary: '#c233ce',
  attentionAlarmBorderSecondary: '#d284d9',
  attentionAlarmBackgroundSecondary: '#f8dafb',
  attentionAlarmInactiveSecondary: '#f1d4f3',

  // Effects
  topBarShadow: '0px 8px 22px rgba(229, 230, 242, 0.4)',
  mainWindowShadow: '0px 8px 24px rgba(226, 227, 241, 0.8)',
  selectedWindowShadow: '0px 18px 41px rgba(196, 198, 216, 0.43)',

  popover: 'rgba(73, 75, 87, 0.95)',
  popoverShadow: '0px 4px 21px rgba(45, 52, 85, 0.51)',

  popoverDark: 'rgba(48, 50, 64, 0.95)',
  popoverDarkShadow: '0px 7px 24px rgba(13, 18, 28, 0.34)',

  inputField: 'rgba(84, 86, 101, 0,15)',
  inputFieldBright: 'rgba(217, 230, 240, 0.05)',

  blueShadow: '0px 7px 28px rgba(84, 102, 253, 0.57)',

  overlay: 'rgba(240, 241, 254, 0.65)',
  overlayModal: 'rgba(72, 74, 87, 0.5)',

  // Tiles
  darkTileShadow: '0px 7px 19px rgba(110, 112, 123, 0,52)',
  blueTileShadow: '0px 7px 19px rgba(84, 102, 253, 0.52)',
  whiteTileShadow: '0px 8px 24px rgba(233, 234, 244, 0.8)',
  darkTileHoverShadow: '0px 7px 22px rgba(70, 75, 110, 0.62)',
  blueTileHoverShadow: '0px 7px 22px rgba(71, 89, 240, 0.62)',
  whiteTileHoverShadow: '0px 8px 24px rgba(233, 234, 244, 0.8)',
};
