import React from 'react';

import cls from 'classnames';

import { Button } from '../Button';

import './LabelButton.scss';

export interface LabelButtonProps {
  children?: any;
  label: any;
  className?: string;
  type: 'primary' | 'secondary' | 'alarm';
  disabled?: boolean;
  loading?: boolean;
  loadingText?: string | JSX.Element;
  size?: 'lg' | 'sm' | 'md';
  onClick?: () => void;
}

export function LabelButton({
  className,
  children,
  label,
  type,
  disabled,
  loading,
  ...attrs
}: LabelButtonProps) {
  return (
    <div
      className={cls('label-button', `label-button-${type}`, className, {
        'is-loading': loading,
        'is-disabled': disabled,
      })}
    >
      <div className="label-button-label">{label}</div>

      <Button type={type} loading={loading} disabled={disabled} {...attrs}>
        {children}
      </Button>
    </div>
  );
}
